.root {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  background: var(--token-p8DIucI8kBQfL9);
  min-width: 0;
  min-height: 0;
  padding: 0px;
}
.header:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  left: auto;
  top: auto;
}
.freeBox__fQpcN {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  max-width: 100%;
  left: auto;
  top: auto;
  height: 100%;
  min-width: 0;
  min-height: 0;
  padding: 168px 8px 8px;
}
@media (min-width: 0px) and (max-width: 768px) {
  .freeBox__fQpcN {
    padding-top: 83px;
  }
}
.section {
  flex-direction: column;
  display: flex;
  width: 60%;
  height: auto;
  padding-bottom: 53px;
}
.section > :global(.__wab_flex-container) {
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin-top: calc(0px - 16px);
  height: calc(100% + 16px);
}
.section > :global(.__wab_flex-container) > *,
.section > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.section > :global(.__wab_flex-container) > picture > img,
.section
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 16px;
}
@media (min-width: 0px) and (max-width: 768px) {
  .section {
    width: 95%;
  }
}
@media (min-width: 0px) and (max-width: 768px) {
  .section > :global(.__wab_flex-container) {
    margin-left: calc(0px - 0px);
    width: calc(100% + 0px);
    margin-top: calc(0px - 0px);
    height: calc(100% + 0px);
  }
}
@media (min-width: 0px) and (max-width: 768px) {
  .section > :global(.__wab_flex-container) > *,
  .section > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
  .section > :global(.__wab_flex-container) > picture > img,
  .section
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > picture
    > img {
    margin-left: 0px;
    margin-top: 0px;
  }
}
.reveal___7NQaO:global(.__wab_instance) {
  max-width: 100%;
  object-fit: cover;
  position: relative;
  transform-origin: -110% 50%;
}
@media (min-width: 0px) and (max-width: 768px) {
  .reveal___7NQaO:global(.__wab_instance) {
    text-align: center;
  }
}
.h1 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  color: var(--token-DwzncEd0zrthaU);
  font-weight: 600;
  min-width: 0;
}
.reveal__yc83X:global(.__wab_instance) {
  max-width: 100%;
  object-fit: cover;
  position: relative;
  transform-origin: -110% 50%;
}
.text__lvT9K {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  color: var(--token-DwzncEd0zrthaU);
  font-size: 48px;
  padding-top: 14px;
  text-align: center;
  min-width: 0;
}
@media (min-width: 0px) and (max-width: 768px) {
  .text__lvT9K {
    text-align: center;
    font-size: 24px;
  }
}
.freeBox__aybBu {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 8px;
}
.reveal__bM9Bv:global(.__wab_instance) {
  max-width: 100%;
  object-fit: cover;
  position: relative;
}
.button:global(.__wab_instance) {
  max-width: 100%;
}
.svg__h4OOj {
  position: relative;
  object-fit: cover;
  width: 1em;
  height: 1em;
}
.text__g8Ubd {
  font-size: 16px;
}
.svg__iBmbq {
  position: relative;
  object-fit: cover;
  width: 1em;
  height: 1em;
}
.footer:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  left: auto;
  top: auto;
}
