.root {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  background: var(--token-p8DIucI8kBQfL9);
  min-width: 0;
  min-height: 0;
  padding: 0px;
}
.header:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  left: auto;
  top: auto;
}
@media (min-width: 0px) and (max-width: 768px) {
  .header:global(.__wab_instance) {
    height: 55px;
    flex-shrink: 0;
  }
}
.freeBox__wlAsf {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  max-width: 100%;
  padding-left: 8px;
  padding-right: 8px;
  padding-bottom: 8px;
  left: auto;
  top: auto;
  height: 100%;
  min-width: 0;
  min-height: 0;
}
@media (min-width: 0px) and (max-width: 768px) {
  .freeBox__wlAsf {
    padding-top: 62px;
  }
}
.section {
  flex-direction: column;
  display: flex;
  width: 60%;
  height: auto;
}
.section > :global(.__wab_flex-container) {
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin-top: calc(0px - 16px);
  height: calc(100% + 16px);
}
.section > :global(.__wab_flex-container) > *,
.section > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.section > :global(.__wab_flex-container) > picture > img,
.section
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 16px;
}
@media (min-width: 0px) and (max-width: 768px) {
  .section {
    width: 95%;
  }
}
@media (min-width: 0px) and (max-width: 768px) {
  .section > :global(.__wab_flex-container) {
    margin-left: calc(0px - 0px);
    width: calc(100% + 0px);
    margin-top: calc(0px - 0px);
    height: calc(100% + 0px);
  }
}
@media (min-width: 0px) and (max-width: 768px) {
  .section > :global(.__wab_flex-container) > *,
  .section > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
  .section > :global(.__wab_flex-container) > picture > img,
  .section
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > picture
    > img {
    margin-left: 0px;
    margin-top: 0px;
  }
}
.text {
  position: relative;
  width: 100%;
  height: auto;
  color: var(--token-DwzncEd0zrthaU);
  padding-bottom: 52px;
  padding-top: 53px;
  text-align: justify;
}
@media (min-width: 0px) and (max-width: 768px) {
  .text {
    text-align: justify;
    padding-bottom: 41px;
    padding-top: 0px;
  }
}
.link__iJtGp {
  color: var(--token-sYxmxfgwmhIT1q);
}
.link__lYuYz {
  color: var(--token-sYxmxfgwmhIT1q);
}
.freeBox___5XgXm {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: auto;
  height: 100px;
  flex-shrink: 0;
  border-radius: 20px;
  padding: 20px;
  border: 4px solid var(--token-sYxmxfgwmhIT1q);
}
@media (min-width: 0px) and (max-width: 768px) {
  .freeBox___5XgXm {
    padding: 20px;
  }
}
.textBox {
  position: relative;
  width: auto;
  height: auto;
  text-align: center;
  color: var(--token-DwzncEd0zrthaU);
  font-size: 32px;
  margin-top: 0px;
  margin-bottom: 0px;
}
@media (min-width: 0px) and (max-width: 768px) {
  .textBox {
    font-size: 24px;
  }
}
.amountInput:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  width: 450px;
  margin-top: calc(30px + 16px) !important;
  margin-bottom: 30px;
}
.svg__hSu1J {
  position: relative;
  object-fit: cover;
  width: 1em;
  height: 1em;
}
.svg__kApTm {
  position: relative;
  object-fit: cover;
  width: 1em;
  height: 1em;
}
.freeBox__vmXfX {
  flex-direction: column;
  display: flex;
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 0px 8px;
}
.freeBox__vmXfX > :global(.__wab_flex-container) {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-width: 0;
  margin-top: calc(0px - 10px);
  height: calc(100% + 10px);
}
.freeBox__vmXfX > :global(.__wab_flex-container) > *,
.freeBox__vmXfX > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__vmXfX > :global(.__wab_flex-container) > picture > img,
.freeBox__vmXfX
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 10px;
}
.msgBox {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 554px;
  text-align: center;
  color: var(--token-DwzncEd0zrthaU);
  min-width: 0;
}
.print:global(.__wab_instance) {
  max-width: 100%;
  height: 35px;
  flex-shrink: 0;
}
.svg__ouqgY {
  position: relative;
  object-fit: cover;
  width: 1em;
  height: 1em;
}
.svg__uTtjH {
  position: relative;
  object-fit: cover;
  width: 1em;
  height: 1em;
}
.action:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  width: 123px;
  height: 22px;
  flex-shrink: 0;
  display: none;
}
.svg___9LKNd {
  position: relative;
  object-fit: cover;
  width: 1em;
  height: 1em;
}
.svg__sfOkm {
  position: relative;
  object-fit: cover;
  width: 1em;
  height: 1em;
}
.footer:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  left: auto;
  top: auto;
}
