.root {
  display: flex;
  flex-direction: row;
  position: relative;
  height: auto;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: nowrap;
  align-content: stretch;
  width: 100%;
  background: var(--token-p8DIucI8kBQfL9);
  max-width: 810px;
  margin-right: 0px;
  min-width: 0;
  padding: 47px 8px 8px;
}
.freeBox__sCzZu {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  align-self: auto;
  min-width: 0;
  padding: 0px 8px 8px;
}
.freeBox__sksCo {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.freeBox__dedr2 {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 8px 0px 0px 8px;
}
@media (min-width: 0px) and (max-width: 768px) {
  .freeBox__dedr2 {
    align-items: center;
    justify-content: center;
  }
}
.text {
  width: 100%;
  height: auto;
  max-width: 800px;
  color: var(--token-DwzncEd0zrthaU);
  min-width: 0;
}
.supplyBox {
  color: var(--token-W9jV5uZrdle7E-);
  font-weight: 700;
}
.span {
  color: var(--token-W9jV5uZrdle7E-);
  font-weight: 700;
}
.freeBox__urzDv {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 0px;
}
@media (min-width: 0px) and (max-width: 768px) {
  .freeBox__urzDv {
    max-width: 10%;
  }
}
.link {
  display: flex;
}
.img {
  object-fit: cover;
  max-width: 100%;
  width: 31px;
  flex-shrink: 0;
}
.img > picture > img {
  object-fit: cover;
}
.img > :global(.__wab_img-spacer) > img {
  object-fit: cover;
}
.userAddressBox {
  height: auto;
  max-width: 800px;
  color: var(--token-W9jV5uZrdle7E-);
  font-weight: 400;
}
