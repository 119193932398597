.root {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  background: var(--token-p8DIucI8kBQfL9);
  min-width: 0;
  min-height: 0;
  padding: 0px;
}
.header:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  left: auto;
  top: auto;
}
.freeBox {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  max-width: 100%;
  left: auto;
  top: auto;
  height: 100%;
  min-width: 0;
  min-height: 0;
  padding: 8px;
}
.section {
  flex-direction: column;
  display: flex;
  width: 60%;
  height: auto;
}
.section > :global(.__wab_flex-container) {
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin-top: calc(0px - 16px);
  height: calc(100% + 16px);
}
.section > :global(.__wab_flex-container) > *,
.section > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.section > :global(.__wab_flex-container) > picture > img,
.section
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 16px;
}
@media (min-width: 0px) and (max-width: 768px) {
  .section {
    width: 95%;
  }
}
@media (min-width: 0px) and (max-width: 768px) {
  .section > :global(.__wab_flex-container) {
    margin-left: calc(0px - 0px);
    width: calc(100% + 0px);
    margin-top: calc(0px - 0px);
    height: calc(100% + 0px);
  }
}
@media (min-width: 0px) and (max-width: 768px) {
  .section > :global(.__wab_flex-container) > *,
  .section > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
  .section > :global(.__wab_flex-container) > picture > img,
  .section
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > picture
    > img {
    margin-left: 0px;
    margin-top: 0px;
  }
}
.text {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  color: var(--token-DwzncEd0zrthaU);
  padding-bottom: 3px;
  font-size: 20px;
  min-width: 0;
}
@media (min-width: 0px) and (max-width: 768px) {
  .text {
    text-align: center;
  }
}
.footer:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  left: auto;
  top: auto;
}
